.select-game-page {
  .h1title {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 7rem);

    .ax-breakpoint-m({
      max-width: calc(100% - 8rem);
      width: auto;
    });
}
}

.selectgame {
  .featured-title {
    display: none;

    .ax-breakpoint-m({
      display: block;
    });
}

.skip-game {
  .button-reset-style();
  background: lighten(@primary-blue, 40%) url('@training/assets/images/no-games-icon.png?_v2') 0.5em center no-repeat;
  background-size: 40px;
  box-sizing: border-box;
  display: block;
  padding: @ax-spacing-m @ax-spacing-m @ax-spacing-m 6.4rem;
  margin-bottom: @ax-spacing-l;
  position: relative;
  width: 100%;

  .ax-breakpoint-m({
    background-size: 50px;
    padding: @ax-spacing-m @ax-spacing-m @ax-spacing-m 5.92rem;
  });

span {
  display: block;
  text-align: left;
}

.no-games-arrow {
  background: url('@training/assets/images/back-icon_black-rtl.png') top center no-repeat transparent;
  height: 35px;
  position: absolute;
  right: 0.5em;
  top: calc(50% - 3.5rem / 2);
  width: 40px;

  .ax-breakpoint-m({
    right: 1em;
  });
}
}
}

.no-games-arrow {
  .rtl-only({
    transform: rotate(180deg)
  });
}

.game-icon(@iconSize: 50, @iconOffset: 0, @iconFloat: left) {
  background-image: url('@training/assets/images/new-game-select-icons.png?_v11');
  background-repeat: no-repeat;
  background-size: unit(@iconSize, px);
  float: @iconFloat;
  display: block;
  .border-radius(@ax-border-radius-m);
  .game-icon-position-by-name(@iconSize, @iconOffset);
}

.gameslist {

  .game-wrapper {
    margin-bottom: @ax-spacing-m;
    min-width: @game-min-width-mobile;

    .ax-breakpoint-m({
      margin-bottom: @ax-spacing-l;
      min-width: auto;
    })
}

.game {
  .display-flex();
  position: relative;
  margin: 0;
  width: 100%;
  text-align: left;
  height: 100%;

  .ax-breakpoint-m({
    width: 100%;
    margin: 0;
    box-sizing: border-box;
    text-align: center;
  });

.game-container {
  width: 100%;
}

.game-meta {
  .flex-grow();

  .ax-breakpoint-m({
    margin: @ax-spacing-s 0;
  });
}

.type {
  .game-icon(50, 5, none);
  height: 60px; // px needed for image sprite background

  .ax-breakpoint-m({
    .border-radius(@ax-border-radius-m);
    height: 100px; // px needed for image sprite background
    width:100%;
    background-image:url('@training/assets/images/new-game-select-icons.png?_v11');
    background-repeat: no-repeat;
    float: none;
    margin: 0;
    background-size: auto;
    .game-icon-position-by-name(100, 0);
  });
}

.row-arrow {
  display: none;
}
}
}

.recent,
.popular,
.challenges {

  .list-view {
    .flex-wrap(nowrap);

    .ax-breakpoint-m({
      .flex-wrap();
    });

  &.scrollable {
    padding-top: .1rem;
  }
}

.ax-breakpoint-m({
  margin-left: 0;
});
}

.recent,
.popular {
  .list-view.scrollable {
    margin-right: @ax-spacing-xs;

    .game-wrapper:last-child:not(:first-child) {
      // Prevent card shadow from being cut off
      padding-right: @ax-spacing-s;
    }
  }
}

.all-games .gameslist {
  margin-top: @ax-spacing-xs;

  .ax-breakpoint-m({
    margin: 0;
    border-top: none;
  });

.game .game-container {
  .box-sizing(border-box);
  .display-flex();
  .align-items(center);
  width: 100%;

  .ax-breakpoint-m({
    .align-items(center);
    .flex-direction(column);
  });

.type {
  .flex-shrink(0);
  height: 60px; // PX needed to accomodate background sprite image
  width: 60px;
  float: left;
  margin-right: @ax-spacing-s;

  .ax-breakpoint-m({
    height: 100px; // PX needed to accomodate background sprite image
    width: 100%;
    float: none;
    margin-right: 0;
    background-color: @primary-bg !important;
  });
}

.row-arrow {
  .row-chevron(auto, auto, auto, auto);
  display: block;
  position: relative;
  margin: 0;

  .ax-breakpoint-m({
    .hidden();
  });
}
}
}

.game-icon-position(@index: 0, @height: 35, @offset: 0) {
  @pos: (@offset - @index * @height);
  background-position: center unit(@pos, px);
}

.game-icon-position-by-name(@size: 50, @offset: 5) {
  &.BallBob {
    .game-icon-position(25, @size, @offset);
    background-color: #b2eab1;
  }

  &.BattleFish {
    .game-icon-position(14, @size, @offset);
    background-color: #b5dee3;
  }

  &.BlackAway {
    .game-icon-position(29, @size, @offset);
    background-color: #dfedc8;
  }

  &.Block {
    .game-icon-position(7, @size, @offset);
    background-color: #f9ce98;
  }

  &.BlockArt {
    .game-icon-position(8, @size, @offset);
    background-color: #d4f2f9;
  }

  &.Breaker {
    .game-icon-position(37, @size, @offset);
    background-color: #e8c3ee;
  }

  &.Bubble {
    .game-icon-position(11, @size, @offset);
    background-color: #cbe9f3;
  }

  &.BumpOut {
    .game-icon-position(27, @size, @offset);
    background-color: #fff3e0;
  }

  &.Catcher {
    .game-icon-position(13, @size, @offset);
    background-color: #fcf1a6;
  }

  &.Cheese {
    .game-icon-position(21, @size, @offset);
    background-color: #f5f0d0;
  }

  &.Collisions {
    .game-icon-position(17, @size, @offset);
    background-color: #F8E6D3;
  }

  &.Convergence {
    .game-icon-position(42, @size, @offset);
    background-color: #d4effc;
  }

  &.CurlingUpdate {
    .game-icon-position(41, @size, @offset);
    background-color: #1A94DB;
  }

  &.Curves {
    .game-icon-position(4, @size, @offset);
    background-color: #d1e2b5;
  }

  &.Elements {
    .game-icon-position(45, @size, @offset);
    background-color: #d4effc;
  }

  &.FieldGoal {
    .game-icon-position(2, @size, @offset);
    background-color: #ecd0cb;
  }

  &.FoodSearch {
    .game-icon-position(32, @size, @offset);
    background-color: #e0bdd9;
  }

  &.Golf {
    .game-icon-position(33, @size, @offset);
    background-color: #dcf1b9;
  }

  &.Head2Head {
    .game-icon-position(23, @size, @offset);
    background-color: #d2e4ec;
  }

  &.Invasion {
    .game-icon-position(22, @size, @offset);
    background-color: #dfedc8;
  }

  &.Jigsaw {
    .game-icon-position(31, @size, @offset);
    background-color: #cdecf8;
  }

  &.LightPuzzle {
    .game-icon-position(40, @size, (@offset - 1)); // Sprite positioning is slightly off
    background-color: #e8c3ee;
  }

  &.LightsOut {
    .game-icon-position(1, @size, @offset);
    background-color: #ffe78c;
  }

  &.Memory {
    .game-icon-position(10, @size, @offset);
    background-color: #ede1b1;
  }

  &.Monkey {
    .game-icon-position(35, @size, @offset);
    background-color: #edeec3;
  }

  &.Pinball {
    .game-icon-position(39, @size, @offset);
    background-color: #e8c3ee;
  }

  &.Pixel {
    .game-icon-position(5, @size, @offset);
    background-color: #f5aaac;
  }

  &.Quiz {
    .game-icon-position(36, @size, @offset);
    background-color: #e8c3ee;
  }

  &.Simon2 {
    .game-icon-position(46, @size, @offset);
    background-color: #414155;
  }

  &.Snake {
    .game-icon-position(9, @size, @offset);
    background-color: #dfedc8;
  }

  &.Soccer {
    .game-icon-position(43, @size, @offset);
    background-color: #b1e7b3;
  }

  &.Space {
    .game-icon-position(3, @size, @offset);
    background-color: #ddb6dd;
  }

  &.Sushi {
    .game-icon-position(6, @size, @offset);
    background-color: #f1ab9c;
  }

  &.Sum {
    .game-icon-position(18, @size, @offset);
    background-color: #f7d59a;
  }

  &.Summer {
    .game-icon-position(47, @size, @offset);
    background-color: #d4effc;
  }

  &.TeamRunner {
    .game-icon-position(38, @size, @offset);
    background-color: #e8c3ee;
  }

  &.TurtleHurtle {
    .game-icon-position(44, @size, @offset);
    background-color: #07b2ef;
  }

  &.Unicycle {
    .game-icon-position(12, @size, @offset);
    background-color: #d7d9ea;
  }

  &.Zombie {
    .game-icon-position(19, @size, @offset);
    background-color: #dfedc8;
  }
}

.game-frame {
  .ax-breakpoint-m({
    border: 0;
  });
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.3),
only screen and (min-resolution: 124.8dpi),
only screen and (min-resolution: 1.3dppx) {
  .gameslist .game .type {
    background-image: url('@training/assets/images/new-game-select-icons@2x.png?_v13');
    background-size: 50px;
  }

  .game-type-icon {
    background-image: url('@training/assets/images/new-game-select-icons.png?_v13');
    background-size: 50px;
  }

  .selectgame .skip-game {
    background-image: url('@training/assets/images/no-games-icon@2x.png?_v2');
    background-size: 40px;
  }

  .selectgame .skip-game .no-games-arrow {
    background: url('@training/assets/images/back-icon_black-rtl.png') top center no-repeat transparent;
    background-size: 40px;
  }
}

@media only screen and (~'min-width: @{ax-breakpoint-tablet-min}') and (-webkit-min-device-pixel-ratio: 1.3),
only screen and (~'min-width: @{ax-breakpoint-tablet-min}') and (min-resolution: 124.8dpi),
only screen and (~'min-width: @{ax-breakpoint-tablet-min}') and (min-resolution: 1.3dppx) {
  .gameslist .game .type {
    background-size: 100px;
  }

  .selectgame .skip-game {
    background-size: 50px;
  }
}

.selectgame .skip-game {
  .rtl-only({
    background-position: 99% center;
  });
}
